import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";

// ASSETS
import iconSecure from "../../../static/images/icons/iconSecure.svg";

// CONTEXT
import UserContext from "../../../context/UserContext";
import UserService from "../../../services/UserService";

// COMPONENTS
import { Checkbox, sideButton } from "@bancobarigui/reactbook";
import { Col, Container, Row } from "../../../components/Grid";
import Title from "../../../components/Typography";
import ButtonWrapper from "../../../components/UI/ButtonWrapper";
import Form from "../../../components/UI/Form";
import Image from "../../../components/UI/Image";
import Input from "../../../components/UI/Input";
import MaskedInput from "../../../components/UI/MaskedInput";
import Select from "../../../components/UI/Select";
import Steps from "../../../components/UI/Steps";

// UTILS
import {
  formInitialValues,
  formValidations,
  removeMask,
} from "../../../utils/formUtils";
import { getTerms } from "../../../utils/getTerms";
import { creditPurposes, formSteps } from "../../../utils/lists";

// CONSTANTS
import { TERM } from "../../../constants/terms";

// STYLES
import { FormWrapper, ImageWrapper, StepsWrapper } from "./styles";

const FormSection = ({ onSuccess, onError }) => {
  const context = useContext(UserContext);
  const { parameters, onChangeValues } = context;
  const [scr, setSCR] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleSubmit = useCallback(
    async (values) => {
      const response = await UserService.sendToken(parameters?.id, values);

      if (
        response === "invalid" ||
        response === "expired" ||
        response === "wrong"
      ) {
        return onError(response);
      }
      onChangeValues(values);
      return onSuccess();
    },
    [context]
  );

  const formik = useFormik({
    initialValues: formInitialValues(parameters),
    validationSchema: formValidations(parameters?.pt),
    onSubmit: (values, { resetForm }) => {
      handleSubmit({
        ...values,
        document: removeMask(values.document),
      });
      resetForm();
    },
  });

  useEffect(() => {
    const getSRCTerm = async () => {
      const data = await getTerms(TERM.SRC);
      setSCR(data.url);
    };

    getSRCTerm();
  }, []);

  return (
    <FormWrapper>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <StepsWrapper>
              <Title size={2}>Preencha os campos do formulário para:</Title>
              <Steps items={formSteps} />
            </StepsWrapper>
            <Image src={iconSecure} alt="Ambiente seguro" isDesktop />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Form onSubmit={formik.handleSubmit}>
              <Input
                type="hidden"
                name="assingSendDate"
                id="assingSendDate"
                value={formik.values.assingSendDate}
              />
              <Input
                type="text"
                id="proponentName"
                name="proponentName"
                label="Nome completo"
                placeholder="Nome"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.proponentName}
                error={
                  formik.touched.proponentName
                    ? formik.errors.proponentName
                    : null
                }
              />
              {parameters?.pt === "PF" ? (
                <MaskedInput
                  type="text"
                  id="document"
                  name="document"
                  label="CPF"
                  size="20"
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.document}
                  error={
                    formik.touched.document ? formik.errors.document : null
                  }
                />
              ) : (
                <MaskedInput
                  type="text"
                  id="document"
                  name="document"
                  label="CNPJ"
                  size="20"
                  mask="99.999.999/9999-99"
                  placeholder="00.000.000/0000-00"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.document}
                  error={
                    formik.touched.document ? formik.errors.document : null
                  }
                />
              )}
              <Select
                id="creditFinality"
                name="creditFinality"
                label="Finalidade do crédito"
                options={creditPurposes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.creditFinality}
                error={
                  formik.touched.creditFinality
                    ? formik.errors.creditFinality
                    : null
                }
                form={formik}
              />
              <MaskedInput
                id="phoneNumber"
                name="phoneNumber"
                label="Celular"
                size="20"
                value={formik.values.phoneNumber}
                error={formik.errors.phoneNumber}
                mask="(99) 9 XXXX-9999"
                placeholder="Celular"
                hint="Um código de verificação será enviado."
                readOnly
              />
              <Checkbox
                checked={formik.values.acceptTerms}
                inputType={"checkbox"}
                label={`Li e estou de acordo com o <a href='${scr}' target='_noblank'>Termo de aceite de consulta ao SCR</a> e ao Cadastro Positivo.`}
                name="acceptTerms"
                onChange={formik.handleChange}
                disabled={!scr}
              />
              <ButtonWrapper
                blue
                disabled={!formik.dirty || !formik.isValid || !scr}
                fixedWidth
                sideIcon={sideButton.RIGHT}
                type="submit"
              >
                {formik.isSubmitting ? "Enviando..." : "Próxima etapa"}
              </ButtonWrapper>
            </Form>
            <ImageWrapper>
              <Image src={iconSecure} alt="Ambiente seguro" isMobile />
            </ImageWrapper>
          </Col>
        </Row>
      </Container>
    </FormWrapper>
  );
};

export default FormSection;
