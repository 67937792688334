import React, { useCallback, useState, useContext } from "react";
import UserResource from "../../../resources/UserResource";
import UserContext from "../../../context/UserContext";
import UserService from "../../../services/UserService";
import formatterPhone from "../../../libs/formatter";
import { Container, Row, Col } from "../../../components/Grid";
import Modal from "../../../components/UI/Modal";
import Input from "../../../components/UI/Input";
import {
  ComponentCodeActivation,
  Back,
  TextBack,
  Button,
  ContentActiveCode,
  HeaderActiveCode,
  TitleActiveCode,
  SubtitleActiveCode,
  ContentInput,
  RefreshCode,
  RefreshCodeText,
  Footer,
  FooterText,
} from "./styles";
import { BackArrow, MobileIcon, SecurityIcon, ResendIcon } from "./svgs";

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-27
 */
const ModalSecurityCode = ({ onClose, active, onSuccess, onError }) => {
  const {
    userParams: { id, proponentName, phoneNumber, creditFinality, document },
    openAlert,
  } = useContext(UserContext);
  const [waitingSendCode, setWaitingCode] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [waitingResendCode, setWaitingResendCode] = useState(false);
  const [timer, setTimer] = useState(60);

  const handleSendCode = useCallback(async () => {
    setWaitingCode(true);

    const response = await UserService.tokenValidate(id, {
      document,
      token: inputCode,
    });

    setWaitingCode(false);

    if (response === "invalid" || response === "expired") {
      return onError(response);
    }

    return onSuccess("success");
  }, [inputCode]);

  const handleChangeInput = useCallback(({ target }) => {
    setInputCode(target.value);
  }, []);

  const startCountdown = () => {
    let countdownTimer = timer;

    const countdown = setInterval(() => {
      countdownTimer -= 1;
      setTimer(countdownTimer);

      if (countdownTimer === 0) {
        clearInterval(countdown);
        setTimer(60);
        setWaitingResendCode(false);
      }
    }, 1000);
  };

  const handleResendCode = useCallback(() => {
    setWaitingResendCode(true);
    startCountdown();

    return UserResource.sendToken(id, {
      creditFinality,
      document,
      proponentName,
    }).then(() => {
      setWaitingResendCode(true);
      startCountdown();
      openAlert(
        "Prontinho! Um novo código de verificação foi enviado",
        "OK, Obrigado"
      );
    });
  }, []);

  const handleBack = useCallback(() => {
    onClose();
  }, []);

  return (
    <Modal active={active}>
      <ComponentCodeActivation>
        <Container className="minContainer">
          <Back>
            <BackArrow />
            {}
            <TextBack href="#" onClick={handleBack}>
              Voltar
            </TextBack>
          </Back>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <ContentActiveCode>
                <MobileIcon />
                <HeaderActiveCode>
                  <TitleActiveCode>
                    Insira o código de confirmação
                  </TitleActiveCode>
                  <SubtitleActiveCode>
                    Nós enviamos um SMS para o número
                    <span>{formatterPhone(phoneNumber)} </span>
                    com um código temporário para validar seus dados. Verifique
                    no seu telefone.
                  </SubtitleActiveCode>
                  <ContentInput>
                    <Input
                      type="number"
                      id="codigo"
                      name="codigo"
                      value={inputCode}
                      min={0}
                      maxLength={6}
                      label=""
                      placeholder="Código de verificação"
                      onChange={handleChangeInput}
                    />
                    <Button
                      onClick={handleSendCode}
                      disabled={inputCode === "" || waitingSendCode}
                    >
                      {waitingSendCode ? "Enviando" : "Enviar"}
                    </Button>
                  </ContentInput>
                  <RefreshCode className={waitingSendCode ? "Disabled" : ""}>
                    <ResendIcon />
                    {!waitingResendCode ? (
                      <RefreshCodeText onClick={handleResendCode}>
                        Reenviar código
                      </RefreshCodeText>
                    ) : (
                      <RefreshCodeText>
                        Código reenviado <span>({timer}s)</span>
                      </RefreshCodeText>
                    )}
                  </RefreshCode>
                </HeaderActiveCode>
              </ContentActiveCode>
            </Col>
          </Row>
          <Footer>
            <SecurityIcon />
            <FooterText>Ambiente 100% seguro</FooterText>
          </Footer>
        </Container>
      </ComponentCodeActivation>
    </Modal>
  );
};

export default ModalSecurityCode;
